<template>
  <preparation :dev="true"></preparation>
</template>

<script>
import Preparation from '../teaching-material/preparation/Preparation.vue'
export default {
  components: {
    Preparation
  }
}
</script>

<style>
</style>
